
import { axios, API_ROUTES } from "@/config/index.js";

export default {
	async find(id) {
		const { data } = await axios.get(`${API_ROUTES.client.find}/${id}`)

		return data
	}
}
